import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import {
    Layout,
    PageHeader,
    Testimonial,
    News,
    LoanWidget,
} from '../components'
import * as Shared from '../styles/shared'

const Loan = props => {
    const { data } = props.pageContext
    // console.log('Loan data ', data)

    const breadcrumbs = {
        heading: {
            title: 'Borrow with Strive',
            link: '/business-loans/'
        },
        description: {
            title: 'Business Loans',
            link: '/business-loans/'
        },
        title: props.pageContext.title
    }

    const renderIntro = () => {
        return (
            <Intro>
                <BGColor className={`bg-color`} />
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.intro_intro_text,
                    }}
                />
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data.intro_paragraph_text,
                    }}
                />
            </Intro>
        )
    }

    const renderFeatures = () => {
        let features

        if (data.features_items) {
            features = data.features_items.map((item, i) => {
                return (
                    <Feature key={i}>
                        <Image key={item.icon.id} src={item.icon.url}>
                            {(src, loading) => {
                                return (
                                    <LoadedImage
                                        src={src}
                                        style={{ opacity: loading ? 0 : 1 }}
                                    />
                                )
                            }}
                        </Image>
                        <Description
                            dangerouslySetInnerHTML={{
                                __html: item.text,
                            }}
                        />
                    </Feature>
                )
            })
        }

        return (
            <Features>
                <BGColor className={`bg-color`} />
                <Heading
                    dangerouslySetInnerHTML={{
                        __html: data.features_heading,
                    }}
                />
                <Grid>{features}</Grid>
            </Features>
        )
    }

    const renderIntroduction = () => {
        return (
            <Wrapper>
                <Introduction>
                    <Grid>
                        <Left>
                            {renderIntro()}
                            {renderFeatures()}
                        </Left>
                        <Right>
                            <LoanWidget size="mini" />
                        </Right>
                    </Grid>
                </Introduction>
            </Wrapper>
        )
    }

    const renderMain = () => {
        let extra_links
        let process_list
        let external_link = /^https?:\/\//i

        if (data.extra_links_display) {
            extra_links = data.extra_links.map((item, i) => {
                // external link
                if (external_link.test(item.link)) {
                    return (
                        <ExternalLink href={`${item.link}`} target={'_blank'}>
                            {item.title}
                        </ExternalLink>
                    )
                } else {
                    return (
                        <Button key={i} to={`${item.link}`}>
                            {item.title}
                        </Button>
                    )
                }
            })
        }

        if (data.process_list) {
            process_list = data.process_list.map((item, i) => {
                return (
                    <Process key={i}>
                        <ProcessNumber>{`${i + 1}.`}</ProcessNumber>
                        <Info>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: item.heading,
                                }}
                            />
                            {item.short_text && (
                                <Description
                                    dangerouslySetInnerHTML={{
                                        __html: item.short_text,
                                    }}
                                />
                            )}
                        </Info>
                    </Process>
                )
            })
        }

        return (
            <Main>
                <Container>
                    <Grid>
                        <Left>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.maintext_heading,
                                }}
                            />
                            <Description
                                dangerouslySetInnerHTML={{
                                    __html: data.maintext_text,
                                }}
                            />
                        </Left>
                        <Right>
                            {extra_links}
                            <ProcessList>
                                <Heading
                                    dangerouslySetInnerHTML={{
                                        __html: data.process_heading,
                                    }}
                                />
                                {process_list}
                                {data.process_use_button_link && (
                                    <div className={`text-center`}>
                                        <Button
                                            to={`${data.process_button_link.url}`}
                                        >
                                            {`${data.process_button_link_text}`}
                                        </Button>
                                    </div>
                                )}
                            </ProcessList>
                        </Right>
                    </Grid>
                </Container>
            </Main>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            {renderIntroduction()}
            {renderMain()}

            {data.testimonials && <Testimonial testimonials={data.testimonials} category={`borrower`} />}
            <Container>
                <LoanWidget />
            </Container>
            {data.news_posts && <News news_posts={data.news_posts} />}
        </Layout>
    )
}

// Shared Child Components

const Heading = Shared.Heading
const Subheading = Shared.Subheading
const Description = Shared.Description
const Info = Shared.Info
const BGColor = Shared.BGColor
const Button = Shared.Button
const Image = Shared.Image
const LoadedImage = Shared.LoadedImage
const ExternalLink = Shared.ExternalLink

// Layout

const Wrapper = Shared.Wrapper
const Container = Shared.Container
const Grid = Shared.Grid
const Left = Shared.Left
const Right = Shared.Right
const Top = Shared.Top
const Bottom = Shared.Bottom

// Top Layout

const Introduction = Shared.Introduction

// Intro

const Intro = Shared.Intro

// Features

const Features = Shared.Features
const Feature = Shared.Feature

// Main

const Main = Shared.Main

// Main Sidebar (Process List)

const ProcessList = styled(Shared.ProcessList)`
    > ${Heading} {
        ${tw`mb-6`};
    }
`

const Process = styled(Shared.Process)`
    ${tw`flex items-start py-3`};
`

const ProcessNumber = styled.div`
    ${tw`flex justify-center items-center w-10 h-10 -mt-2 mr-4 leading-none bg-white rounded-full text-darkBlue`};
    flex-shrink: 0;
    flex-grow: 0;
    font-weight: 700; /* bold */
    font-size: 1.3125rem;
`

export default Loan
